<template>
    <vx-card :title="'Check Status of Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="reqCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(unloadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unlCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="36%">SKU Code</th>
                            <th width="10%">Unload HU</th>
                            <th width="7%">Unload Qty</th>
                            <th width="10%">Status</th>
                            <th width="10%">HU</th>
                            <th width="7%">Qty</th>
                            <th width="10%">Batch</th>
                            <th width="10%">Exp. Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(dataLine, index) in dataLines">
                            <!-- first row -->
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                :rowspan="dataLines[index].add_line.length">
                                    <vs-input :value="(dataLine.item_name) ? dataLine.item_name : ''" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                :rowspan="dataLines[index].add_line.length">
                                    <vs-input :value="dataLine.ul_selected_hu" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px"
                                :rowspan="dataLines[index].add_line.length">
                                    <vs-input :value="dataLine.ul_qty" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input :value="dataLines[index].add_line[0].selected_status" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input :value="dataLines[index].add_line[0].check_selected_hu" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="number" class="w-full" v-model="dataLines[index].add_line[0].check_qty" :readonly="true"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="text" class="w-full" v-model="dataLines[index].add_line[0].batch" :readonly="true"></vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input :value="formatDate(dataLines[index].add_line[0].exp_date)" :readonly="true" class="w-full"></vs-input>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(addLine, indexAddLine) in dataLines[index].add_line">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input :value="dataLines[index].add_line[indexAddLine].selected_status" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input :value="dataLines[index].add_line[indexAddLine].check_selected_hu" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input type="number" class="w-full" v-model="dataLines[index].add_line[indexAddLine].check_qty" :readonly="true"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input type="text" class="w-full" v-model="dataLines[index].add_line[indexAddLine].batch" :readonly="true"></vs-input>
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        <vs-input :value="formatDate(dataLines[index].add_line[indexAddLine].exp_date)" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import Date from "./component/Date.vue";

export default {
    components: {
        vSelect,
        Date,
    },
    data() {
        return {
            id: null,
            readonly: true,
            reqCode: null,
            unlCode: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            unloadingDate: null,
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    ul_selected_hu: null,
                    ul_qty: 0,
                    ul_qty_uom: 0,

                    add_line: [{
                        check_id: 0,
                        selected_status: null,
                        check_selected_hu: null,
                        check_qty: 0,
                        batch: null,
                        exp_date: null,
                    }]
                }
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/canvas/check-data/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.reqCode = resp.data.request_doc
                        this.unlCode = resp.data.unloading_doc
                        this.selectedWarehouse = resp.data.selected_warehouse.label
                        this.selectedSales = resp.data.selected_sales.label
                        this.loadingDate = moment(resp.data.loading_date).toDate()
                        this.unloadingDate = moment(resp.data.unloading_date).toDate()
                        this.dataLines = resp.data.data_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.item-request",
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>